@import "../../common/scss-setup/index";

.table> :not(:first-child) {
  border-top: none !important;
}

.site-name {
  text-align: left;
  font-size: $enormous-font-size;
  color: $light-green;
  border: none;
  margin-top: -6px;
  cursor: default;
}

.table thead th {
  padding-left: 24px;
  padding-top: 28px;
  padding-bottom: 28px;
  vertical-align: middle;
}

.site-table {
  background-color: #ffffff;
  margin: 2em 0;
  text-align: left;

  .no-results {
    text-align: center;
  }

  .btn-refresh {
    margin: 10px;
    background-color: #4a800b;
    border-color: #4a800b;

    &:hover {
      background-color: $dark-green;
    }
  }

  .table-body {
    &:hover {
      background-color: $table-hover-background;
      cursor: pointer;
    }

    td {
      border-top: 1px solid;
      border-bottom: none;
      border-top-color: $table-border;
      padding-left: 24px;
    }

    .edit-button-column {
      padding-right: 24px;
      justify-content: flex-end;
      text-align: right;

      button {
        color: $medium-green;
        margin-bottom: 4px;
        margin-top: 4px;
      }

      button:hover {
        background-color: $medium-green;
        color: #ffffff;
      }

      button:focus {
        box-shadow: none;
      }
    }

    .table-record-description {
      border: none;
      padding-top: 0;
      padding-bottom: 0;

      .measurement-description {
        display: inline-flex;
        font-size: $tiny-font-size;
        color: #6d6f74;
        float: left;
        text-align: left;
        padding-right: 0.4rem;
        margin-top: -2px;

        .comment-icon {
          padding-top: 0.22rem;
          padding-right: 0.25rem;
        }

        .description-text {
          font-size: $smaller-font-size;
        }

        .no-data {
          font-size: $tiny-font-size;
        }

        .measurement-tag {
          font-size: $smaller-font-size;
        }

        .tag-icon {
          padding: 0.22rem 0.25rem 0 0.1rem;
          font-size: $tiny-font-size;
        }
      }
    }

    .table-record-description.visible {
      padding-bottom: 0.5rem;
    }

    .table-measures-row td {
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }

    .table-measures-row {
      height: 40px;
    }
  }

  .date-and-time {
    width: 294px;
  }

  .user {
    width: 270px;
  }

  .device {
    width: 190px;
  }

  .cartridge {
    width: 172px;
  }

  .batch {
    width: 150px;
  }

  .result {
    width: 100px;
  }

  .show-details-column {
    width: 180px;
    text-align: right;
    padding-right: 24px;
    padding-top: 28px;
    padding-bottom: 27px;

    button {
      font-size: $smaller-font-size;
      color: $medium-green;
      background-color: #ffffff;
      border-color: $medium-green;
      padding-top: 6px;
      padding-right: 30px;
      padding-bottom: 2px;
      white-space: nowrap;
      width: 135px;

      &:focus {
        box-shadow: none;
      }
    }

    button.btn-show-details:hover {
      background-color: $medium-green;
      color: #ffffff;
    }
  }
}