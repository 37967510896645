$base-font-size: 16px !default;

$mini-font-size: 0.625rem !default; //10
$tiny-font-size: 0.75rem !default; //12
$smaller-font-size: 0.875rem !default; //14
$small-font-size: 1rem !default; //16
$medium-font-size: 1.125rem !default; //18
$large-font-size: 1.375rem !default; //22
$larger-font-size: 1.5rem !default; //24
$big-font-size: 1.75rem !default; //28
$huge-font-size: 2rem !default; //32
$enormous-font-size: 2.5rem !default; //40
$ginormous-font-size: 4.5rem !default; //72
