@import "../../../common/scss-setup/index";

.warning-level-ok {
  padding: 12px 0;
  color: $dark-green-text;
}

.warning-level-warn {
  padding: 12px 0;
  color: $base-text;
}

.warning-level-bad {
  padding: 12px 0;
  color: $red-button;
}

.warning-undefined {
  height: 50px;
  display: flex;

  span {
    padding-top: 10px;
  }
}
