@import "../../common/scss-setup/index";

.modal-test-details {
  font-family: Verdana, sans-serif;
}

.modal-test-details .modal-dialog {
  width: 60vw;
}

.modal-test-details .header {
  color: $medium-green;
  border-bottom: none;
}

.modal-test-details .body .item {
  border: none;
}

.modal-test-details .body .form-text {
  font-size: $tiny-font-size;
}

.modal-test-details .body .form-label {
  font-size: $tiny-font-size;
  color: $medium-gray;
}

.record-form {
  padding-top: 1rem;
}

.modal-test-details .footer {
  border-top: none;
  justify-content: left;
}

.modal-test-details .body .btn-modal-save {
  width: 20%;
  margin-right: 3%;
  background-color: $medium-green;
  border-color: $medium-green;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.modal-test-details .body .btn-modal-cancel {
  width: 20%;
  background-color: white;
  border-color: $medium-green;
  color: $medium-green;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.tags-input {
  font-family: Verdana, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.tag-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  border: 1px solid $medium-gray;
  border-radius: 5px;
  padding: 10px;
}
.tag {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 25px;
  margin: 2px 5px 2px 0px;
  color: #ffffff;
  background-color: $medium-green;
}

.tag-container input {
  border: none;
  flex: 1;
  outline: none;
  background: #ffffff;
  color: #000000;
  width: 30px;
}
.tag span {
  margin-left: 5px;
  cursor: pointer;
}

.fade-yellow-effect-on {
  background-color: $fade-yellow-effect;
}

.fade-yellow-effect-off {
  background-color: #ffffff;
  transition: background-color 1s;
}
