@import "../../common/scss-setup/index";

.col .card {
  border: none;
  border-radius: 0;
}

.col .card {
  width: 300px;
  .card-body {
    padding: 0;
    .site-data-row {
      padding-left: 12px;
    }
  }
}

.warning-icon {
  padding: 12px 0;
}

.custom-card-site-statistics-data {
  font-size: $medium-font-size;
  color: $base-text;
  padding-top: 8px;
  margin-bottom: 0;
  padding-bottom: 15px;

  .tests-count {
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .card-body {
    padding: 0;
  }
}
.card-wrapper.col {
  width: 324px;

  .custom-card-site {
    height: 150px;
    border: none;
    padding-left: 12px;

    .custom-card-site-header {
      font-size: $tiny-font-size;
      color: $medium-gray;
    }

    .custom-card-site-footer {
      font-size: $mini-font-size;
      color: $medium-gray;
    }

    .title-card {
      display: flex;
      justify-content: space-between;
      padding-right: 1rem;
      padding-top: 6px;
      width: 300px;
      word-break: break-all;

      .card-title {
        font-size: $large-font-size;
      }
      .edit-site-btn {
        border: none;
        background-color: transparent;
      }
    }

    .dropdown-toggle {
      background-color: transparent;
      border: none;
      color: $base-text;

      &:focus {
        box-shadow: none;
      }

      &:after {
        display: none !important;
      }
    }

    .test-count {
      padding: 0;
    }

    .min-score {
      padding: 0;
    }

    .avg-score {
      padding: 0;
    }
  }
}

.modal-site {
  font-family: Verdana, sans-serif;
}

.modal-site .modal-dialog {
  width: 60vw;
}

.modal-dialog .modal-content .modal-body {
  padding-top: 0;
}

.modal-site .header {
  color: $medium-green;
  border-bottom: none;
}

.modal-site .body .item {
  border: none;
}

.modal-site .body .form-text {
  font-size: $tiny-font-size;
}

.modal-site .body .form-label {
  font-size: $tiny-font-size;
  color: $medium-gray;
}

.modal-site .footer {
  border-top: none;
  justify-content: left;
}

.modal-site .body .btn-modal-save {
  width: 20%;
  margin-right: 3%;
  background-color: $medium-green;
  border-color: $medium-green;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.modal-site .body .btn-modal-cancel {
  width: 20%;
  background-color: white;
  border-color: $medium-green;
  color: $medium-green;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.modal-site .body .btn-modal-delete {
  width: 20%;
  margin-right: 3%;
  background-color: $red-button;
  border-color: $red-button;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $red-button-hover;
    border-color: $red-button-hover;
  }
}

@media only screen and (max-width: 1000px) {
  .custom-card-site {
    height: 200px;
  }
}

.custom-card-site:hover {
  background-color: $card-site-hover;
  cursor: pointer;
}
