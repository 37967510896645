@import "../../common/scss-setup/index";
.table-container {
  padding-left: 36px;
  .contaminants-levels-table {
    .separator {
      position: absolute;
      border: 1px solid $table-border;
      width: calc(100vw - 260px);
    }

    @media only screen and (max-width: 1000px) {
      .separator {
        border: 1px solid $table-border;
        width: 700px;
      }
    }

    .label,
    .unit {
      padding-top: 16px;
    }

    .input {
      width: 100px;
      height: 44px;
      border: 1px solid $light-gray;
      border-radius: 4px;
      font-size: 16px;
      padding-left: 8px;
      color: #495057;

      &:focus {
        border: 1px solid #4a800b;
        outline: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    width: 720px;
    thead {
      height: 41px;
      th {
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        padding-right: 20px;
      }
    }
  }
}
