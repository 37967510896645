@import "../../common/scss-setup/index";

.filters {
  display: flex;
  padding-top: 1em;

  .row {
    .filterItem {
      vertical-align: middle;
      display: flex;
      font-size: $smaller-font-size;

      label {
        padding-right: 0.5em;
        padding-left: 0.75rem;
      }

      .deviceId {
        padding-left: 0;
      }

      .filterSelect {
        min-width: 140px;
        max-width: 160px;
      }

      .filterLabel {
        padding-top: 14px;
        width: 100px;
        padding-left: 15px;
      }
      .filterLabel-batch{
        padding-top: 14px;
        width: 160px;
      }
      .filterLabel-device{
        padding-top: 14px;
        width: 160px;
        padding-left: 0px;
      }
    }
  }
  .filterLabel {
    padding-top: 12px;
  }
  .select-search-input{
    width: 190px;
  }
}

