@import "../../common/scss-setup/index";

.main {
  width: 100%;

  justify-content: space-between;

  .select-pagination-size {
    display: flex;
    align-items: center;
    padding-top: 1em;

    .text {
      padding-right: 0.5rem;
      font-size: 14px;
    }

    button {
      border-color: $medium-green;
    }

    button[aria-expanded="true"] {
      background-color: $medium-green;
    }
    button[aria-expanded="false"] {
      background-color: $light-green;
      border-color: $medium-green;
      box-shadow: none;
    }
  }

  .pagination {
    justify-content: right;
    margin-bottom: 0;
    padding-top: 1em;
  }

  .page-item .page-link {
    color: $medium-green;
    box-shadow: none;

    &:focus {
      background-color: white;
    }
    &:hover {
      background-color: #dee2e6;
    }
  }

  .page-item.active .page-link {
    background-color: $light-green;
    color: white;
    border-color: transparent;
    box-shadow: none;
  }
}

.right-side {
  justify-content: end;
  padding-top: 1rem;
  .row {
    justify-content: end;
  }
}
