@import "common/scss-setup/index";

.App {
  text-align: center;
  font-family: Verdana, sans-serif;
  text-decoration: none solid;
  background-color: $background-color;
  min-height: 100vh;
  min-width: 900px;
}

.page-container {
  margin: 0 4.5em;
  height: 100%;
  padding-bottom: 2rem;
}

.welcome-page {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  font-size: $enormous-font-size;
  color: $light-green;
  text-decoration: none solid;
  font-weight: bold;

  .logo {
    padding-top: 90px;
  }

  .button {
    padding-top: 54px;

    button {
      width: 556px;
      height: 32px;
      padding: 0 5px;
      border: none;
      background-color: $medium-green;

      &:hover {
        background-color: $dark-green;
      }
    }
  }
}

.error-page {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  text-decoration: none solid;
  font-weight: bold;

  .logo {
    padding-top: 90px;
  }

  h1 {
    color: $light-green;
    font-size: $ginormous-font-size;
    line-height: 48px;
  }

  h3 {
    color: $base-text;
    font-size: $large-font-size;
    padding-top: 30px;
  }

  .button {
    padding-top: 20px;

    button {
      width: 103px;
      height: 32px;
      padding: 0 5px;
      border: none;
      font-size: 16px;
      background-color: $medium-green;
    }
  }
}
