@import "../../common/scss-setup/index";

.navbar-style {
  justify-content: center;
  background-color: $light-green;
  height: 60px;
  padding-top: 0;
}

.navbar {
  position: sticky !important;
  top: 0;
  z-index: 100;
  flex-wrap: nowrap !important;

  .username-login-container {
    width: 100%;
  }
}

.nav-style .nav-brand {
  padding-top: 0;
}

a.fon.nav-link {
  font-size: $small-font-size;
  color: #ffffff;
}

button.logout-button {
  padding-top: 0;
  font-size: $smaller-font-size;
  color: $dark-green;
}

button.logout-button:hover {
  color: #ffffff;
}

.custom-logo {
  padding-left: 1.5rem;
  font-size: $big-font-size;
  display: flex;

  .logo-name {
    padding-top: 0.4rem;
    padding-left: 0.75rem;
  }
}

.login-logout-container {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;

  .username {
    color: #ffffff;
    padding-top: 0.2rem;
    white-space: nowrap;
  }
  .logout-navbar {
    padding-right: 0;
    margin-right: 0;
    white-space: nowrap;
  }
  a {
    padding-right: 0;
    margin-right: 0;
  }
}

.navbar-nav .nav-link button {
  margin-top: -5px;
}

.navbar-nav {
  display: block !important;
}

.navbar-dark .navbar-nav .login-logout-container .nav-link {
  display: initial;
  color: $dark-green;
  padding: 0;
  &:hover {
    color: $hover-dirty-white;
  }
}
