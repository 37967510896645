@import "../../common/scss-setup/index";

.organization-header {
  display: flex;
  padding-right: 11rem;
  padding-top: 2rem;

  .organization-header-buttons {
    justify-content: right;
    .configure-contaminants-level-button {
      display: flex;
      width: 130px;
      height: 32px;
      margin: 0 12px;

      align-self: center;
      justify-content: center;

      color: $dark-green-text;
      background-color: transparent;
      border-color: $dark-green;

      .btn-content {
        margin-top: -2px;
      }

      &:hover {
        background-color: $dark-green;
        border-color: $dark-green;
      }

      &:focus {
        background-color: $medium-green;
        border-color: $medium-green;
        box-shadow: none;
      }
    }
    .add-site-button {
      width: 82px;
      height: 32px;

      align-self: center;

      background-color: $medium-green;
      border-color: $medium-green;

      .btn-content {
        margin-top: -2px;
        padding-left: 6px;
      }

      &:hover {
        background-color: $dark-green;
        border-color: $dark-green;
      }

      &:focus {
        background-color: $medium-green;
        border-color: $medium-green;
        box-shadow: none;
      }
    }
  }

  .organization-select {
    white-space: nowrap;
    display: flex;

    .dropdown-menu {
      min-width: 350px;

      .dropdown-item {
        &:hover {
          color: $light-green;
        }
      }
    }

    .organization-dropdown-button {
      font-size: $enormous-font-size;
      color: $light-green !important;
      background-color: $background-color;
      border: none;
      padding-left: 0px;
      white-space: normal;

      &[aria-expanded="true"] {
        color: #69b70f;
        background-color: #f7f7f8 !important;
        border: none;
        padding-left: 0px;
        box-shadow: none;
      }

      &[aria-expanded="false"] {
        color: #69b70f;
        background-color: #f7f7f8 !important;
        border: none;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        background-color: $background-color;
        color: $light-green;
      }
    }

    a {
      font-size: $huge-font-size;
      color: $light-green;
      background-color: #ffffff;
      border: none;
    }

    .organization-select-item {
      font-size: $big-font-size;
      padding-left: 12px;
      white-space: normal;

      &:hover {
        background-color: #f0f8e7;
      }
    }
  }

  .organization-name {
    text-align: left;
    font-size: $enormous-font-size;
    color: $light-green;
    border: none;
    margin-top: -6px;
    cursor: default;

    p {
      margin-bottom: 0;
    }
  }
}
