@import "../../../common/scss-setup/index";

.trend-grow {
  color: $red-button;
  padding-top: 7px;
  padding-bottom: 7px;
}

.trend-no-change {
  color: $base-text;
  padding-top: 7px;
  padding-bottom: 7px;
}

.trend-decrease {
  color: $dark-green-text;
  padding-top: 7px;
  padding-bottom: 7px;
}

.trend-undefined {
  height: 50px;
  display: flex;

  span {
    padding-top: 10px;
  }
}

.bi-arrow-up-right-square {
  font-size: 1.5rem;
}

.bi-arrow-right-square {
  font-size: 1.5rem;
}

.bi-arrow-down-right-square {
  font-size: 1.5rem;
}
