@import "../../common/scss-setup/index";

.navigation {
  padding-top: 2.2rem;
  font-size: $enormous-font-size;

  .navigation-previous-page {
    text-align: left;
    margin: 0;
    font-size: $smaller-font-size;

    button {
      padding: 0;
      margin: 0;
      border: none;
      color: $medium-gray;
      background-color: transparent;
    }
  }
}
