@import "../../common/scss-setup/index";

.contaminants-levels {
  background-color: white;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #e0e0e0;

  .header {
    font-size: $large-font-size;
    color: $dark-green-text;
    text-align: left;
    padding-left: 36px;
    padding-top: 36px;
    margin-bottom: 0;
  }

  .buttons {
    padding: 36px 0;
    display: flex;
    .btn-modal-save {
      width: 82px;
      height: 32px;
      margin-right: 12px;
      background-color: $medium-green;
      border-color: $medium-green;

      padding: 0;

      &:hover {
        background-color: $dark-green;
        border-color: $dark-green;
      }
    }

    .btn-modal-cancel {
      width: 82px;
      height: 32px;
      background-color: white;
      border-color: $medium-green;
      color: $medium-green;

      padding: 0;

      &:hover {
        background-color: $dark-green;
        border-color: $dark-green;
        color: white;
      }
    }
  }
}
